<template>
  <div>
    <HgAuthModal
      v-if="showAuthModal"
      :is-mobile="isMobile"
      @onClose="closeAuthModal"
    >
      <HgTabs>
        <template :slot="$t('authModal.login')">
          <HgLoginForm
            :loading="loading"
            :forgotPasswordLink="localePath('auth-login')"
            @onSubmit="login"
          >
            <template #success>
              <HgAlertBox
                v-if="loginSuccess"
                :title="$t('authSuccessTitle')"
                type="success"
              >
                <p>
                  {{ $t('loginSuccess') }}
                </p>
              </HgAlertBox>
            </template>
            <template v-if="errors" #errors>
              <HgAlertBox
                :title="$t('errorHandling.errorBoxTitle')"
                type="error"
              >
                <APIErrors :errors="errors" />
              </HgAlertBox>
            </template>
            <template v-else #errors>
              <hg-alert-box>
                {{ $t('authModal.loginOldAccountWarning') }}
              </hg-alert-box>
            </template>

            <template #form-footer>
              <div class="modal-footer-container">
                <div class="modal-footer-border">
                  <div></div>
                  <p>{{ $t('authModal.loginWith') }}</p>
                  <div></div>
                </div>

                <div class="login-buttons-container">
                  <HgLoginButton variant="apple" @onClick="appleLogin()" />
                  <HgLoginButton
                    variant="facebook"
                    @onClick="facebookLogin()"
                  />
                  <HgLoginButton variant="google" @onClick="googleLogin()" />
                </div>
              </div>
            </template>
          </HgLoginForm>
        </template>
        <template :slot="$t('authModal.register')">
          <HgRegisterForm :loading="loading" @onSubmit="register">
            <template #success>
              <HgAlertBox
                v-if="registerSuccess"
                :title="$t('afterRegistration.almostThere')"
                type="success"
              >
                <p>
                  {{ $t('registerSuccess') }}
                </p>
              </HgAlertBox>
            </template>
            <template v-if="errors" #errors>
              <HgAlertBox
                :title="$t('errorHandling.errorBoxTitle')"
                type="error"
              >
                <APIErrors :errors="errors" />
              </HgAlertBox>
            </template>
            <template #form-footer>
              <div class="modal-footer-container">
                <div class="modal-footer-border">
                  <div></div>
                  <p>{{ $t('authModal.registerWith') }}</p>
                  <div></div>
                </div>

                <div class="login-buttons-container">
                  <HgLoginButton variant="apple" @onClick="appleLogin()" />
                  <HgLoginButton
                    variant="facebook"
                    @onClick="facebookLogin()"
                  />
                  <HgLoginButton variant="google" @onClick="googleLogin()" />
                </div>
              </div>
            </template>
          </HgRegisterForm>
        </template>
      </HgTabs>
    </HgAuthModal>
  </div>
</template>

<script>
import AuthMixin from '@/mixins/AuthMixin';

export default {
  mixins: [AuthMixin],
  watch: {
    isAuthenticated() {
      if (this.isAuthenticated && this.isAuthModalActive === true) {
        this.$store.commit('setShowAuthModal', false);
      }
    }
  },
  methods: {
    async appleLogin() {
      try {
        let result = await AppleID.auth.signIn();
        this.loginWithApple(result.authorization.id_token);
      } catch (e) {
        console.error(e);
      }
    },
    facebookLogin() {
      FB.login((response) => {
        if (response.authResponse && response.authResponse.accessToken) {
          this.loginWithFacebook(response.authResponse.accessToken);
        }
      });
    },
    async googleLogin() {
      try {
        const googleUser = await this.$gAuth.signIn();
        const idToken = googleUser.getAuthResponse().id_token;
        this.loginWithGoogle(idToken);
      } catch (e) {
        console.error(e);
      }
    },
    close() {
      this.$store.commit('setShowAuthModal', false);
    }
  }
};
</script>

<style scoped>
.modal-footer-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.modal-footer-border {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  margin-bottom: 16px;

  div {
    width: 100%;
    height: 1px;
    border-top: 1px solid black;
  }

  p {
    white-space: nowrap;
    font-weight: 500;
    font-size: 14px;
    margin: 0;
  }
}

.login-buttons-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}

@media screen and (min-width: 474px) {
  .login-buttons-container {
    flex-direction: row;
  }
}
</style>
